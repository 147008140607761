<template>
  <div>
    <div class="card">
      <div class="nav-panel anan-tabs anan-tabs-line anan-tabs-normal anan-tabs-top">
        <div class="anan-tabs__nav">
          <div
            class="anan-tabs__nav-warp px-2"
            style="margin-top: 5px;"
          >
            <div
              class="anan-tabs__nav-tabs"
              style="transform: translateX(0px)"
            >
              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === 'all' }"
                style="white-space: normal"
                @click="getData(status = 0,search_val), isActive = 'all'"
              >
                <span> {{ $t('all') }} </span>
              </div>

              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === 'not print' }"
                style="white-space: normal"
                @click="getData(status = 1,search_val), isActive = 'not print'"
              >
                <span> {{ $t('cancel') }} </span>
              </div>

              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === 'printed' }"
                style="white-space: normal"
                @click="getData(status = 2,search_val), isActive = 'printed'"
              >
                <span> {{ $t('pendingReview') }} </span>
              </div>

              <div
                class="anan-tabs__nav-tab"
                :class="{ active: isActive === 'waiting' }"
                style="white-space: normal"
                @click="getData(status = 3,search_val), isActive = 'waiting'"
              >
                <span> {{ $t('operationCompleted') }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-lg-flex justify-content-between align-items-center px-2 mt-2">
        <h3 class="font-weight-bolder">
          {{ $t('withdrawFromSystem') }}
        </h3>

        <div class="d-flex align-items-center">
          <div class="anan-input__inner anan-input__inner--normal">
            <input
              v-model="search_val"
              type="text"
              :placeholder="$t('search')"
              class="anan-input__input"
              @keyup.enter="getData(status,search_val)"
            >
            <div class="anan-input__suffix">
              <button
                type="button"
                class="anan-button anan-button--normal anan-button--primary"
                @click="getData(status,search_val)"
              >
                <i class="fal fa-search" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <b-overlay
        :show="showOver"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              กรุณารอสักครู่...
            </p>
          </div>
        </template>
        <div class="p-2">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            show-empty
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner
                  label="กำลังโหลด..."
                  variant="danger"
                />
              </div>
            </template>
            <template #empty>
              <div class="text-center">
                <img
                  src="@/assets/images/anan-img/empty/empty.png"
                  alt="empty"
                  width="180px"
                >
                <p>No Data</p>
              </div>
            </template>

            <template #cell(itemnumber_text)="data">
              <b-link :to="{name: 'admin-finance-withdraw-view', params: {id: data.item._id}}">
                {{ data.item.itemnumber_text }}
              </b-link>
            </template>

            <template #cell(username)="data">
              <b-link :to="{name: 'admin-employee-edit', params: {id: data.item.uid}}">
                {{ data.item.username }}
              </b-link>

            </template>

            <template #cell(created_at)="data">
              {{ time(data.item.created_at) }}
            </template>
            <template #cell(approvalDate)="data">
              {{ data.item.approve_time ? data.item.approve_time: '-' }}
            </template>

            <template #cell(amount)="data">
              {{ Commas(data.item.amount) }} ฿
            </template>
            <template #cell(file_slip)="data">
              <img
                v-if="data.item.file_slip"
                :src="GetImg('Filewithdraw',data.item.file_slip)"
                alt="img-document"
                class="border"
                height="40"
                @click="showPicture(data.item.file_slip)"
              >
              <vue-image-lightbox-carousel
                ref="lightbox"
                :show="showLightbox"
                :images="images"
                :show-caption="false"
                @close="closeBox()"
              />
            </template>
            <template #cell(document)="data">
              <img
                :src="data.item.document"
                alt="img-document"
                class="border"
                height="40"
              >
            </template>

            <template #cell(status)="data">
              <span
                v-if="data.item.status === 'waiting'"
                class="badge badge-primary badge-pill"
              > {{ $t('pendingReview') }}
              </span>
              <span
                v-if="data.item.status === 'success'"
                class="badge badge-success badge-pill"
              > {{ $t('operationCompleted')
              }}</span>
              <span
                v-if="data.item.status === 'restore'"
                class="badge badge-secondary badge-pill"
              > {{ $t('cancelled') }}
              </span>

            </template>

            <template #cell(actions)="data">
              <router-link :to="{name : 'admin-finance-withdraw-view', params: { id: data.item._id }}">
                <feather-icon
                  icon="Edit3Icon"
                  class="mr-1 text-primary cursor-pointer"
                />
              </router-link>
            </template>
          </b-table>

          <div class="demo-spacing-0 d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @input="getData(status,search_val)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-overlay>
    </div>
    <!-- modal -->
    <b-modal
      ref="my-modal"
      hide-footer
      :title="$t('key-34')"
    >
      <img
        :src="pic"
        alt="..."
        class="img-fluid rounded"
      >

      <div class="text-center">
        <button
          type="button"
          class="btn btn-primary mt-2"
          @click="downloadImg"
        >
          Download
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import vSelect from 'vue-select'
import moment from 'moment-timezone'
// import flatPickr from 'vue-flatpickr-component'
import {
  // BRow,
  // BCol,
  VBTooltip, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BFormInput, BButton, BCardBody, VBToggle, BOverlay, BLink,
  // BIconController,
  BBadge, BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  components: {
    flatPickr,
    // BRow,
    // BCol,
    // vSelect,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BButton,
    BCardBody,
    BOverlay,
    // BIconController,
    BBadge,
    BFormRadio,
    BLink,
    VueImageLightboxCarousel,
    // flatPickr,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isActive: 'all',
      search_type: null,
      search_val: '',
      show: false,
      show2: false,
      Selected: null,
      depositdata: [],
      member: '',
      amount: 0,
      optionsMember: '',
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },

      /* eslint-disable global-require */
      items: [],
      addNewDataSidebar: false,
      UserData: JSON.parse(localStorage.getItem('userData')),
      tel: null,
      search: false,
      dateStart: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      balance: 0,
      itembank: [],
      itemBankList: [],
      status: 0,
      showOver: false,
      images: [],
      showLightbox: false,
    }
  },
  computed: {
    search_type_option() {
      return [
        { value: 1, text: this.$t('username') },
        { value: 2, text: this.$t('key-300') },
        { value: 3, text: this.$t('key-301') },
        { value: 4, text: this.$t('key-302') },
      // { value: 5, text: 'สถานะไม่สำเร็จ' },
      // { value: 6, text: 'สถานะยึดเครดิต' },
      ]
    },
    fields() {
      return [
        {
          key: 'itemnumber_text', label: this.$t('orderNumber'), thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        // { key: 'detail', label: this.$t('customerDetails') },
        // { key: 'frome', label: this.$t('fromBank') },
        {
          key: 'username', label: this.$t('customer'), thClass: 'text-center', thStyle: { width: '15%' }, tdClass: 'text-center',
        },
        {
          key: 'created_at', label: this.$t('transactionDate'), thClass: 'text-center', thStyle: { width: '5%' }, tdClass: 'text-center',
        },
        // { key: 'tel', label: 'เบอร์โทร' },
        // { key: 'cus', label: 'ชื่อลูกค้า' },
        // { key: 'acc_no', label: 'เลขบัญชี' },
        {
          key: 'approvalDate', label: this.$t('approvalDate'), thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'file_slip', label: this.$t('evidence'), thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'amount', label: this.$t('quantity'), thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'status', label: this.$t('status'), thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'actions', label: this.$t('manage'), thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },

      ]
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    '$route.query.querykey': {
      immediate: true,
      handler() {
        this.handleQueryChange()
      },
    },
  },
  created() {
    this.interval = setInterval(() => {
      this.handleQueryChange()
    }, 60000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
  mounted() {
    // this.totalRows = this.items.length
    // this.getData()
    this.getBankSelect()
  },
  methods: {
    handleQueryChange() {
      const { querykey } = this.$route.query
      if (querykey) {
        if (Number(querykey) === 2) {
          this.isActive = 'printed'
          this.getData(this.status = 2)
        }
      } else {
        this.getData(this.status)
      }
    },
    getbalance(uid) {
      this.show = true
      const params = {
        uid,
      }
      this.$http
        .get('/Balance/getbalance', { params })
        .then(response => {
          this.balance = response.data.balance
          this.show = false
        })
        .catch(error => console.log(error))
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: this.GetImg('Filewithdraw', picture) }
      this.images.push(img)
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    getUsersSearch() {
      this.show2 = true
      const obj = {
        tel: this.tel,
      }
      this.$http
        .post('/users/search', obj)
        .then(response => {
          this.member = response.data
          this.getbalance(this.member.id)
          this.getBank(this.member.id, this.member.agent_id)
          this.search = true
          this.amount = 0
          this.show2 = false
        })
        .catch(error => console.log(error))
    },
    time(data) {
      return moment(data).format('DD/MM/YYYY HH:mm:ss')
    },
    getData() {
      this.showOver = true
      const params = {
        perPage: this.perPage,
        page: this.currentPage,
        search_val: this.search_val,
        search_type: this.status,
      }
      this.$http.get('/Withdraw/GetData', { params })
        .then(response => {
          this.items = response.data.data
          this.totalRows = response.data.total
          this.showOver = false
        })
        .catch(err => {
          console.log(err)
          this.SwalError(err)
          this.showOver = false
        })
      // this.show = true
      // const arry = []
      // const params = {
      //   page: this.currentPage,
      //   perPage: this.perPage,
      //   search_val: this.search_val,
      //   search_type: this.search_type,
      //   dateStart: this.dateStart,
      //   dateEnd: this.dateEnd,
      // }
      // this.$http
      //   .get('/withdraw', { params })
      //   .then(response => {
      //     // this.show = false
      //     this.depositdata = response.data.data
      //     // console.log(response.data)
      //     this.depositdata.forEach(items => {
      //       if (items.status === 'waiting_approve') {
      //         arry.push(items)
      //       }
      //     })
      //     // localStorage.setItem('alertwit', JSON.stringify(arry))
      //     this.onFiltered(response.data)
      //   })
      //   .catch(error => console.log(error))
    },
    // eslint-disable-next-line camelcase
    getBank(id, agent_id) {
      const params = {
        id,
        agent_id,
      }
      this.$http.get('/Refill/getBankUser', { params })
        .then(response => {
          this.itembank = response.data
          // console.debug(response)
        })
        .catch(err => {
          console.log(err)
          this.SwalError(err)
        })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.data
      this.totalRows = filteredItems.total
      // this.currentPage = 1
    },
    // getAdmin(addby, index) {
    //   this.$http
    //     .get(`/admin/show/${addby}`)
    //     .then(response => {
    //       // console.log(response.data.name)
    //       this.show = false
    //       this.depositdata[index].approveby = response.data.name
    //     })
    //     .catch(error => console.log(error))
    // },
    Confirm(data, type) {
      if (type === 'approve') {
        this.showtext = 'อนุมัติ'
      } else if (type === 'restore') {
        this.showtext = 'คืนเงิน'
      }
      this.$swal({
        title: 'แจ้งเตือน ?',
        // eslint-disable-next-line no-undef
        text: `ยืนยันที่จะ ${this.showtext} หรือไม่ ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          if (type === 'approve') {
            this.approve(data)
          } else if (type === 'restore') {
            this.restore(data)
          }
        } else {
          this.show = false
          this.$swal('ยกเลิก', `ยกเลิกการ${this.showtext}`, 'error')
        }
      })
    },
    async getBankSelect() {
      try {
        const { data: res } = await this.$http.get('/bank')
        this.itemBankList = res.map(ele => ({
          id: ele.id,
          bankcode: ele.bankcode,
          name: ele.name,
          path_photo: `/bankIcon/${ele.path_photo}`,
        }))
      } catch (e) {
        console.log(e)
      }
    },
    submit() {
      if (!this.member) {
        this.SwalError('กรอกข้อมูล ยูสเซอร์เนม/เบอร์โทรศัพท์')
      } else if (!this.Selected) {
        this.SwalError('เลือกช่องทางการโอน')
      } else if (this.balance < this.amount) {
        this.SwalError('ยอดเงินไม่เพียงพอ')
      } else if (!this.amount) {
        this.SwalError('กรอกจำนวนเงิน')
      } else {
        const obj = {
          amount: this.amount,
          acc_no: this.Selected.number_account,
          cus_bank: this.Selected.name_account,
          bank: this.Selected.bank_code,
          bankname: this.Selected.name_bank,
          path_photo: null,
          uid: this.Selected.uid,
          agent_id: this.Selected.agent_id,
          username: this.Selected.username,
        }
        this.$http.post('/Withdraw/Admin', obj)
          .then(response => {
            if (response.data.status) {
              this.Success('บันทึกข้อมูลเรียบร้อย')
              this.$bvModal.hide('modal-1')
              this.getWithdraw()
            }

            // this.$router.push({ name: 'balance' })
          })
          .catch(err => {
            this.SwalError(err.response.data.message)
          })
      }
      // if (this.Selected === 'truewallet') {
      //   this.member.acc_no = this.member.trueaccno
      //   this.member.bank = 'truewallet'
      // }
      // this.show = true
      // const formData = {
      //   username: this.member.username,
      //   uid: this.member.id,
      //   amount: this.amount,
      //   acc_no: this.member.acc_no,
      //   bank: this.member.bank,
      // }
      // this.$http
      //   .post('/withdraw/store', formData)
      //   .then(() => {
      //     this.show = false
      //     this.getWithdraw()
      //     this.username = ''
      //     this.amount = ''
      //     this.balance = 0
      //     this.Success('ถอนเครดิต สำเร็จ')
      //   })
      //   .catch(error => {
      //     this.SwalError(error.response.data.message)
      //     this.show = false
      //     this.getWithdraw()
      //     this.username = ''
      //     this.amount = ''
      //     this.balance = 0
      //     this.show = false
      //   })
    },
    approve(val) {
      this.show = true
      const formData = val
      this.$http
        .post('/Withdraw/UpdateWithdraw', formData)
        .then(() => {
          this.show = false
          this.getWithdraw()
          this.username = null
          this.amount = 0
          this.Success('ถอนเครดิต สำเร็จ')
        })
        .catch(error => {
          this.show = false
          this.SwalError(error)
        })
    },
    restore(val) {
      this.show = true
      const formData = val
      this.$http
        .post('/Withdraw/UpdateRestore', formData)
        .then(() => {
          this.show = false
          this.getWithdraw()
          this.username = null
          this.amount = 0
          this.Success('คืนเงิน สำเร็จ')
        })
        .catch(error => {
          this.show = false
          this.SwalError(error)
        })
    },
    holdcredit(val) {
      this.show = true
      const formData = val
      this.$http
        .post('/withdraw/holdcredit', formData)
        .then(() => {
          this.show = false
          this.getWithdraw()
          this.username = ''
          this.amount = ''
          this.Success('ยึดเครดิต สำเร็จ')
        })
        .catch(error => {
          this.show = false
          this.SwalError(error.response.data.message)
        })
    },
    manual(val) {
      this.show = true
      const formData = val
      this.$http
        .post('/withdraw/manual', formData)
        .then(() => {
          this.show = false
          this.getWithdraw()
          this.username = ''
          this.amount = ''
          this.Success('ถอนเครดิต สำเร็จ')
        })
        .catch(error => {
          this.show = false
          this.SwalError(error.response.data.message)
        })
    },
    submitHold() {
      this.show = true
      const formData = {
        username: this.member.username,
        uid: this.member.id,
        amount: this.amount,
      }
      this.$http
        .post('/withdraw/holdstore', formData)
        .then(() => {
          this.show = false
          this.getWithdraw()
          this.username = ''
          this.amount = ''
          this.Success('ถอนเครดิต สำเร็จ')
        })
        .catch(error => {
          this.show = false
          this.SwalError(error.response.data.message)
        })
    },
    Get_userlist() {
      this.$http
        .get('/api/user/list')
        .then(response => {
          this.onFiltered(response.data.message)
        })
        .catch(error => console.log(error))
    },
    addData() {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = {}
      this.tel = null
      this.search = false
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = data
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>
  <style scoped>
    .bank-icon {
      width: 35px;
      height: 35px;
      padding: 5px;
      border-radius: 50%;
    }
  </style>

  <style lang="scss" scoped>
  @import "~@core/scss/base/pages/app-invoice.scss";
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .form-item-section {
  background-color:$product-details-bg;
  }

  </style>
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  </style>
